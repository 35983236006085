<template>
  <div class="min-h-screen flex-1 mt-1 bg-gray-100 px-4 pt-12 items-center">
    <div class="bg-white w-fullrounded-lg shadow">
      <div class="h-20 py-3 flex items-center border-gray-200 p-6 mt-5">
        <div class="flex">
          <button
            :class="$colors.primary"
            class="rounded-full flex justify-center items-center w-8 h-8 mr-3"
            @click="RouterBack()"
          >
            <i class="material-icons">chevron_left</i>
          </button>
          <div class="text-xl font-bold text-gray-700">{{$t('detail_product')}}</div>
        </div>
      </div>

      <div class="p-32"></div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      formData: {},
    };
  },
  async mounted() {},
  methods: {
      RouterBack: function() {
                this.$router.back();
            },
    async save() {},
    async getData() {},
  },
};
</script>


<style >
</style>